import { DeleteOutlined, EditOutlined, EyeFilled } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { Button, Tooltip } from 'antd';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {
  LIST_TYPES,
  ROUTES,
  SUBSCRIPTION_SORT_FIELD,
  WORKSPACE_ROLE_LEVEL,
  WORKSPACE_ROLE_PERMISSION
} from '../../common/constants';
import { modalContext } from '../../components/AppComponentContainer';
import FilterBar, { useFilterBar } from '../../components/FilterBar';
import PageHeader from '../../components/PageHeader';
import PageList from '../../components/PageList';
import useCheckPermission from '../../hooks/useCheckPermission';
import { DELETE_PLAN } from './graphql/Mutations';
import { GET_SUBSCRIPTION_PLANS } from './graphql/Queries';

const dataSelector = (data) => ({
  data: data?.subscriptionPlans?.subscriptionPlans ?? [],
  count: data?.subscriptionPlans?.count ?? 0
});

const variablesSelector = ({ offset, sortField, sortOrder, ...rest }) => ({
  filter: {
    skip: offset,
    ...rest
  },
  sort: {
    sortOn: sortField,
    sortBy: sortOrder
  }
});

const Action = ({ record, removeItem }) => {
  const { id } = record;

  const isFreeOrGuest = ['FREE', 'GUEST'].includes(record?.key);

  const history = useHistory();
  const [deleteSubscriptionPlan] = useMutation(DELETE_PLAN, {
    refetchQueries: [
      {
        query: GET_SUBSCRIPTION_PLANS,
        variables: {
          filter: {
            skip: 0,
            limit: 10
          },
          sort: {
            sortOn: 'createdAt',
            sortBy: 'DESC'
          }
        }
      }
    ],
    onError() {}
  });

  const handleEdit = () => {
    history.push({
      pathname: `${ROUTES?.SUBSCRIPTION_PLANS}/${id}/edit`
    });
  };

  const isViewOnly = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.SUBSCRIPTION_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.VIEW]
    }
  ]);

  const isDeleteAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.SUBSCRIPTION_MANAGEMENT,
      allowedPermissions: [WORKSPACE_ROLE_LEVEL.DELETE]
    }
  ]);

  const handleDelete = () => {
    if (isFreeOrGuest) return;
    modalContext?.confirm({
      title: 'Are you sure, you want to delete this plan?',
      centered: true,
      okText: 'Yes',
      cancelText: 'No',
      okType: 'primary',
      onOk: async () => {
        return deleteSubscriptionPlan({
          variables: { where: { id } }
        })
          .then(({ errors }) => {
            if (removeItem && !errors) {
              removeItem(id);
            }
          })
          .catch((err) => err);
      }
    });
  };

  return (
    <div className="d-flex align-center">
      <Tooltip title={`${isViewOnly ? 'View' : 'Edit'} Plan`}>
        <Button type="text" className="text-btn" onClick={handleEdit}>
          {isViewOnly ? <EyeFilled /> : <EditOutlined />}
        </Button>
      </Tooltip>
      {isDeleteAllowed && (
        <Tooltip title="Delete Plan">
          <Button
            disabled={isFreeOrGuest}
            type="text"
            className="text-btn"
            onClick={handleDelete}
          >
            <DeleteOutlined />
          </Button>
        </Tooltip>
      )}
    </div>
  );
};

const columns = ({ isAddEditAllowed, removeItem }) => {
  return [
    {
      title: 'Key',
      dataIndex: 'key',
      ellipsis: true,
      width: '20%'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      ellipsis: true,
      width: '30%'
    },
    {
      title: 'Frequency',
      dataIndex: 'frequency',
      ellipsis: true,
      render: (frequency) => {
        return <div>{frequency ?? '-'}</div>;
      }
    },
    {
      title: 'Order',
      dataIndex: 'order',
      render: (order) => {
        return <div>{order ?? '-'}</div>;
      }
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      render: (amount) => {
        return (
          <div>
            {new Intl.NumberFormat('en', {
              style: 'currency',
              currency: 'USD'
            }).format(amount?.usd?.price)}{' '}
          </div>
        );
      }
    },
    {
      title: 'Action',
      dataIndex: 'id',
      render: (_, record) => (
        <Action
          record={record}
          isAddEditAllowed={isAddEditAllowed}
          removeItem={removeItem}
        />
      )
    }
  ];
};

const SubscriptionPlans = ({ history }) => {
  const isAddEditAllowed = useCheckPermission([
    {
      moduleKey: WORKSPACE_ROLE_PERMISSION.SUBSCRIPTION_MANAGEMENT,
      allowedPermissions: [
        WORKSPACE_ROLE_LEVEL.EDIT,
        WORKSPACE_ROLE_LEVEL.DELETE
      ]
    }
  ]);

  const filterProps = useFilterBar({
    defaultSortOptions: {
      options: SUBSCRIPTION_SORT_FIELD,
      field: SUBSCRIPTION_SORT_FIELD?.[0]?.value
    }
  });

  const filters = useMemo(() => ({ ...filterProps?.filters }), [
    filterProps?.filters
  ]);

  const handleShowModal = () => {
    history.push(`${ROUTES?.SUBSCRIPTION_PLANS}/add`);
  };

  return (
    <div>
      <PageHeader
        handleShowModal={handleShowModal}
        isAddEditAllowed={isAddEditAllowed}
      />
      <FilterBar
        {...filterProps}
        show={{
          listModes: false
        }}
        SearchProps={{ placeholder: 'Search Plans' }}
      />
      <PageList
        filters={filters}
        listMode={LIST_TYPES?.TABLE}
        query={GET_SUBSCRIPTION_PLANS}
        variablesSelector={variablesSelector}
        dataSelector={dataSelector}
        TableProps={{
          columns: (props) =>
            columns({ ...props, isAddEditAllowed })?.filter(
              (item) => item !== false
            )
        }}
        limit={20}
      />
    </div>
  );
};

export default SubscriptionPlans;
